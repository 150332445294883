export const nftStakingABI = [
  {
    inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'collection', type: 'address' }],
    name: 'CollectionClose',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'lockTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'interestRate', type: 'uint256' },
    ],
    name: 'CollectionNew',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'lockTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'interestRate', type: 'uint256' },
    ],
    name: 'CollectionUpdate',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'NonFungibleTokenRecovery',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'claimer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'revenue', type: 'uint256' },
    ],
    name: 'RevenueClaim',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'key', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'minimumNFT', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'maximumNFT', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
    ],
    name: 'SettingCollectionNew',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'key', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'minimumNFT', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'maximumNFT', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
    ],
    name: 'SettingCollectionUpdate',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: true, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'StakingComplete',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: true, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'revenuePerSecond', type: 'uint256' },
    ],
    name: 'StakingNew',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'collection', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'StopStakingEmergency',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'TokenRecovery',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_lockTime', type: 'uint256' },
      { internalType: 'uint256', name: '_interestRate', type: 'uint256' },
    ],
    name: 'addCollection',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_minimumNFT', type: 'uint256' },
      { internalType: 'uint256', name: '_maximumNFT', type: 'uint256' },
      { internalType: 'uint256', name: '_revenuePerSecond', type: 'uint256' },
    ],
    name: 'addStakingRevenueSetting',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
    ],
    name: 'claimPendingRevenue',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_collection', type: 'address' }],
    name: 'closeCollectionForStakingAndListing',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
    ],
    name: 'completeStakingOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
    ],
    name: 'createStakingOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_account', type: 'address' },
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
    ],
    name: 'getPendingRevenue',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_lockTime', type: 'uint256' },
      { internalType: 'uint256', name: '_interestRate', type: 'uint256' },
    ],
    name: 'modifyCollection',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_key', type: 'uint256' },
      { internalType: 'uint256', name: '_minimumNFT', type: 'uint256' },
      { internalType: 'uint256', name: '_maximumNFT', type: 'uint256' },
      { internalType: 'uint256', name: '_revenuePerSecond', type: 'uint256' },
    ],
    name: 'modifyStakingRevenueSetting',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
    name: 'recoverFungibleTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
    ],
    name: 'recoverNonFungibleToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: '_collection', type: 'address' },
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
    ],
    name: 'stopStakingEmergency',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'tokenAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewCollections',
    outputs: [
      { internalType: 'address[]', name: 'collectionAddresses', type: 'address[]' },
      {
        components: [
          { internalType: 'enum JoiStaking.CollectionStatus', name: 'status', type: 'uint8' },
          { internalType: 'uint256', name: 'lockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'interestRate', type: 'uint256' },
        ],
        internalType: 'struct JoiStaking.Collection[]',
        name: 'collectionDetails',
        type: 'tuple[]',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewStakingsByCollection',
    outputs: [
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      {
        components: [
          { internalType: 'address', name: 'staker', type: 'address' },
          { internalType: 'uint256', name: 'revenuePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'unLockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'lastClaimTime', type: 'uint256' },
        ],
        internalType: 'struct JoiStaking.Staking[]',
        name: 'stakingInfo',
        type: 'tuple[]',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'address', name: 'staker', type: 'address' },
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewStakingsByCollectionAndStaker',
    outputs: [
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      {
        components: [
          { internalType: 'address', name: 'staker', type: 'address' },
          { internalType: 'uint256', name: 'revenuePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'unLockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'lastClaimTime', type: 'uint256' },
        ],
        internalType: 'struct JoiStaking.Staking[]',
        name: 'stakingInfo',
        type: 'tuple[]',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'collection', type: 'address' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
    ],
    name: 'viewStakingsByCollectionAndTokenIds',
    outputs: [
      { internalType: 'bool[]', name: 'statuses', type: 'bool[]' },
      {
        components: [
          { internalType: 'address', name: 'staker', type: 'address' },
          { internalType: 'uint256', name: 'revenuePerSecond', type: 'uint256' },
          { internalType: 'uint256', name: 'unLockTime', type: 'uint256' },
          { internalType: 'uint256', name: 'lastClaimTime', type: 'uint256' },
        ],
        internalType: 'struct JoiStaking.Staking[]',
        name: 'stakingInfo',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const
