import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M23,7.35c-.28,0-.55-.11-.75-.34-.57-.65-1.39-1.02-2.25-1.02H4c-.86,0-1.68,.37-2.25,1.02-.37,.41-1,.45-1.41,.09-.41-.37-.45-1-.09-1.41,.95-1.08,2.32-1.69,3.75-1.69H20c1.43,0,2.8,.62,3.75,1.69,.37,.41,.33,1.05-.09,1.41-.19,.17-.43,.25-.66,.25Zm.66-4.25c.41-.37,.45-1,.09-1.41-.95-1.08-2.32-1.69-3.75-1.69H4C2.57,0,1.2,.62,.25,1.69c-.37,.41-.33,1.05,.09,1.41,.41,.36,1.05,.33,1.41-.09,.57-.65,1.39-1.02,2.25-1.02H20c.86,0,1.68,.37,2.25,1.02,.2,.22,.47,.34,.75,.34,.23,0,.47-.08,.66-.25Zm-4.66,12.9c0,1.42-2.99,3-7,3s-7-1.58-7-3,2.99-3,7-3,7,1.58,7,3Zm-4.5,0c0-.55-1.12-1-2.5-1s-2.5,.45-2.5,1,1.12,1,2.5,1,2.5-.45,2.5-1Zm9.5-3v6c0,2.76-2.24,5-5,5H5c-2.76,0-5-2.24-5-5v-6c0-2.76,2.24-5,5-5h14c2.76,0,5,2.24,5,5Zm-3,3c0-2.85-3.87-5-9-5S3,13.15,3,16s3.87,5,9,5,9-2.15,9-5Z"
      />
    </Svg>
  );
};

export default Icon;
