/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from "react";
import styled from "styled-components";
import isTouchDevice from "../../util/isTouchDevice";
import MenuItem from "../MenuItem/MenuItem";
import { MenuItemsProps } from "./types";

const BlockMenu = styled.div`
  @media screen and (max-width: 1199px) {
    position: fixed;
    top: unset;
    bottom: 0px;
    height: 60px;
    will-change: transform;
    backface-visibility: hidden;
    transition: 0.4s all;
    // border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    transition: 0.4s all;
    flex-direction: row;
    width: 100%;
    right: 0px;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  z-index: 99;
  margin: auto;
  width: 70px;
  background: ${({ theme }) => theme.colors.backgroundElement};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 90px 0px 0px 0px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
`;

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({ items = [], activeItem }) => {
  return (
    <BlockMenu>
      {items.map(({ label, items: menuItems = [], href, icon, image, disabled }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
        const isActive = activeItem === href;
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? { href } : { href };
        const Icon = icon;

        return (
          <MenuItem
            key={`${label}#${href}`}
            {...linkProps}
            image={image}
            isActive={isActive}
            statusColor={statusColor}
            isDisabled={disabled}
          >
            <p>{label}</p>
            {icon && createElement(Icon as any, { color: isActive ? "invertedContrast" : "textSubtle" })}
          </MenuItem>
        );
      })}
    </BlockMenu>
  );
};

export default memo(MenuItems);
